<template>
  <ModalMain
    v-if="userProfile"
    dim-close
    bg-close-disable
    @close="$emit('close')"
  >
    <div class="px-4 md:px-7 pt-4 w-full">
      <h1 class="text-2xl tracking-wide font-semibold mb-2 text-center">{{ title }}</h1>
      <VForm
        v-slot="{ values, invalid }"
        ref="RegisterForm"
        :validation-schema="validationSchema"
        :initial-values="formData"
        @submit="onSubmit"
      >
        <InputValidated
          v-show="!infoRegisterModalEmailOnly"
          name="username"
          type="text"
          placeholder="Username"
          autocomplete="off"
          :disabled="isLoading || !canUpdateUsername"
          :value="userData?.displayName"
          @input="updateValues(values); debounceCheckDisplayName(values)"
        />

        <div v-if="canUpdateUsername && (formData.username === userData?.displayName) && !infoRegisterModalEmailOnly" class="flex text-xs -mt-1.5">
          <div class="text-xs text-slate-50 leading-tight flex items-center">
            <span class="icon-ico-user text-base font-bold mr-0.5"/>
            <span>Proposed username. Feel free to customize.</span>
          </div>
        </div>

        <InputValidated
          name="email"
          type="email"
          placeholder="Email"
          :optional="!emailRequired"
          :disabled="isLoading || !canUpdateEmail"
          :value="userProfile?.email"
          autocomplete="off"
          @input="updateValues(values)"
        />

        <div v-if="!userProfile?.email" class="text-xs text-slate-50 leading-tight flex items-center">
          <span class="icon-ico-info text-base font-bold mr-0.5"/>
          <span>Add your email to unlock premium access and free entry to prize competitions.</span>
        </div>

        <div class="flex text-xs -mt-1">
          <div
            v-if="userProfile?.emailVerified"
            class="text-slate-50 flex-auto text-right"
          >
            <span
              class="icon-ico-tick font-bold -top-[1px] relative align-middle z-10 text-green"
            />
            Verified
          </div>
          <div
            v-else
            class="text-green flex-auto text-right"
          >
            <ButtonButton
              v-if="userProfile?.email && !userProfile?.emailVerified && !hasChanges"
              size="2xs"
              type="button"
              :disabled="isLoading"
              @click.prevent.stop="$emit('register-submit')"
            >
              Verify
            </ButtonButton>
          </div>
        </div>

        <InputValidated
          name="referralCode"
          type="text"
          placeholder="Referral Code (Optional)"
          optional
          :value="referralCodeValue"
          autocomplete="off"
          @input="updateValues(values)"
        />

        <div v-if="error" class="text-red text-center text-sm">{{ error }}</div>

        <ButtonButton
          v-if="canUpdate"
          :is-loading="isLoading"
          type="submit"
          :disabled="invalid || isLoading"
          class="block w-full mt-4"
        >
          Save changes
        </ButtonButton>
      </VForm>
    </div>
  </ModalMain>
</template>

<script>
import { mapActions, mapState, mapWritableState } from 'pinia';
import * as VeeValidate from 'vee-validate';
import * as yup from 'yup';

import { useUserStore } from '@/store/user';
import { useAuthStore } from '@/store/auth';
import { useDeviceStore } from '@/store/device';
import { useUiStore } from '@/store/ui';

export default defineComponent({
  name: 'ModalAccountRegisterUpdate',
  components: {
    VForm: VeeValidate.Form,
  },
  emits: [
    'register-submit',
    'close',
  ],
  setup() {
    const {
      ACCOUNT_EMAIL_REQUIRED: emailRequired,
      ACCOUNT_EMAIL_UPDATE: emailUpdateEnabled,
      ACCOUNT_USERNAME_REQUIRED: usernameRequired,
      ACCOUNT_USERNAME_UPDATE: usernameUpdateEnabled,
    } = useRuntimeConfig().public;
    return { emailRequired, emailUpdateEnabled, usernameRequired, usernameUpdateEnabled, };
  },
  data() {
    return {
      debounce: null,
      formData: {
        username: '',
        email: '',
      },
      isUsernameAvailable: true,
      isUsernameValid: true,
      isLoading: true,
      emailVerifyCode: null,
      error: null,
      emailValue: '',
    };
  },
  computed: {
    ...mapWritableState(useUserStore, ['userData', 'userProfile',]),
    ...mapWritableState(useAuthStore, ['isUserLogged', 'user',]),
    ...mapWritableState(useUiStore, ['showInfoRegisterModal', 'infoRegisterModalEmailOnly',]),
    ...mapState(useDeviceStore, ['isMobileDevice', 'browser',]),

    title() {
      return this.infoRegisterModalEmailOnly ? 'Verify Email' : 'Welcome';
    },
    validationSchema() {
      return yup.object().shape({
        email: yup.string().email().label('Email').when([], {
          is: () => this.emailRequired,
          then: schema => schema.required(),
          otherwise: schema => schema.optional(),
        }),
        username: yup.string().label('Username').when([], {
          is: () => this.usernameRequired && !this.infoRegisterModalEmailOnly,
          then: schema => schema.required(),
          otherwise: schema => schema.optional(),
        })
        .test(
          'usernameLength',
          'Username is too short',
          value => !value || value.length >= 5
        )
        .test(
          'usernameAvailable',
          'Username is already taken',
          value => !value || this.isUsernameAvailable || (!this.isUsernameAvailable && !this.isUsernameValid)
        ).test(
          'usernameValid',
          'Please choose a different username',
          value => !value || this.isUsernameValid
        ),
      });
    },
    canUpdateUsername() {
      return !this.userData?.displayName || (this.usernameUpdateEnabled && this.usernameUpdateRestrictionExpired);
    },
    canUpdateEmail() {
      return !this.userProfile?.email || !this.userProfile?.emailVerified || (this.emailUpdateEnabled && this.emailUpdateRestrictionExpired);
    },
    canUpdate() {
      return this.canUpdateUsername || this.canUpdateEmail;
    },
    hasChanges() {
      return this.formData.username !== this.userData?.displayName || this.formData.email !== this.userProfile?.email;
    },
    usernameUpdateRestrictionExpired() {
      return !this.userData?.displayNameUpdateTime || this.$dayjs(this.userData?.displayNameUpdateTime).isBefore(this.$dayjs().subtract(24, 'hours'));
    },
    emailUpdateRestrictionExpired() {
      return !this.userProfile?.emailUpdateTime || this.$dayjs(this.userProfile?.emailUpdateTime).isBefore(this.$dayjs().subtract(24, 'hours'));
    },
  },
  mounted() {
    this.init();
  },
  unmounted() {
    this.infoRegisterModalEmailOnly = false;
  },
  methods: {
    ...mapActions(useUserStore, ['getUserData', 'getUserProfile', 'setUsername', 'updateUserProfile',]),
    ...mapActions(useAuthStore, ['verifyEmail', 'verifyEmailCode',]),
    async init() {
      await this.getUserProfile();
      this.isLoading = false;
      if (this.userData && this.userData?.displayName) {
        this.formData = {
          username: this.userData?.displayName,
          email: this.userProfile?.email,
        };
      }
    },
    updateValues(values) {
      this.formData.username = values.username;
      this.formData.email = values.email;
      this.formData.referralCodeValue = values.referralCode;
      this.error = '';
    },
    debounceCheckDisplayName() {
      // Debounce and call API to check availability and validity of the username
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.checkDisplayName();
      }, 500);
    },
    async checkDisplayName() {
      if (this.infoRegisterModalEmailOnly) { return true; }
      if (this.formData.username === '') {
        return false;
      }
      try {
        const res = await this.$nuxt.$api('/user/display-name/check', {
          method: 'POST',
          body: { name: this.formData.username, },
        });
        this.isUsernameAvailable = res.available;
        this.isUsernameValid = res.valid;
        // force validation on username field so that it kicks in even when field is blurred already
        this.$refs.RegisterForm.validateField('username');
      } catch (err) {
        useNuxtApp().$rollbar.error('method failed', err);
      }
    },
    async postReferralCode() {
      this.error = null;
      try {
        const response = await this.$nuxt.$api('/referral/code', {
          method: 'POST',
          body: {
            code: this.formData.referralCodeValue,
          },
        });
        if (!response) {
          this.error = 'Referral Code failed, please try again or with a different code.';
        }
      } catch (err) {
        if (err?.status === 403) {
          this.error = 'Referral Code no longer accepted.';
        } else {
          this.error = 'Referral Code incorrect, please try again or with a different code.';
        }
        useNuxtApp().$rollbar.error('postReferralCode failed:::', err);
      }
    },
    async onSubmit(values) {
      this.isLoading = true;
      this.error = null;

      try {
        if (this.formData.referralCodeValue) {
          await this.postReferralCode();
        }
        if (!this.error) {
          if (this.canUpdateUsername && values.username && values.username !== this.userData?.displayName) {
            await this.setUsername(values.username);
          }

          if (this.canUpdateEmail && values.email && values.email !== this.userProfile?.email) {
            await this.updateUserProfile({
              email: values.email,
            });
            this.$emit('register-submit');
          } else {
            this.showInfoRegisterModal = false;
            this.$emit('close');
          }
        }
      } catch (err) {
        this.error = err.response?._data?.message || err.message;
      } finally {
        this.isLoading = false;
      }
      return false;
    },
    async refreshData() {
      await this.getUserData();
      await this.getUserProfile();
    },
  },
});
</script>
